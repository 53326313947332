import { deleteCookie, getCookie } from 'cookies-next';
import { isEmpty } from 'lodash';

import { AUTH_TOKEN } from '../constants';
import objectToQueryString from '../utils/objectToQueryString';

enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

interface BaseRequest {
  method?: string;
  headers?: HeadersInit;
  body?: FormData | BodyInit | null | Record<string, unknown>;
  next?: NextFetchRequestConfig;
}

const REVALIDATE_DEFAULT = 10;

const fetchHeaders = new Headers({
  'Cache-Control': 'no-cache',
});

export const authHeaders = (withoutAuth?: boolean): Record<string, string | undefined> => {
  const authHeaderFromStorage = getCookie(AUTH_TOKEN);
  const headerObject = authHeaderFromStorage
    ? { Authorization: `Bearer ${authHeaderFromStorage as string}` }
    : {};
  return withoutAuth ? {} : headerObject;
};

export const generateHeaders = (withoutAuth?: boolean): HeadersInit => {
  return {
    ...fetchHeaders,
    ...authHeaders(withoutAuth),
  };
};

const baseRequest = (url: string, reqInit?: BaseRequest, withoutAuth?: boolean) =>
  fetch(url, {
    ...reqInit,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...generateHeaders(withoutAuth),
      ...reqInit?.headers,
    },
    body: JSON.stringify(reqInit?.body),
    next: {
      revalidate: reqInit?.next?.revalidate ?? REVALIDATE_DEFAULT,
      tags: [...(reqInit?.next?.tags || []), url],
    },
  }).then(async response => {
    const ok = response.status >= 100 && response.status <= 399;
    if (response.status === 401) {
      deleteCookie(AUTH_TOKEN);
    }
    const json = await response.json().catch(() => response);
    return ok ? json : Promise.reject(new Error(JSON.stringify(json)));
  });

const baseFormDataRequest = (url: string, reqInit?: BaseRequest, withoutAuth?: boolean) =>
  fetch(url, {
    ...reqInit,
    headers: {
      Accept: 'application/json',
      ...generateHeaders(withoutAuth),
      ...reqInit?.headers,
    },
    body: reqInit?.body as FormData,
    next: reqInit?.next, // Include Next.js 14 specific options
  }).then(async response => {
    const ok = response.status >= 100 && response.status <= 399;
    const json = await response.json().catch(() => response);
    return ok ? json : Promise.reject(new Error(JSON.stringify(json)));
  });

export const postRequest = <T>({
  url,
  body,
  withoutAuth,
  headers,
  next,
}: {
  url: string;
  body?: BodyInit | Record<string, unknown>;
  withoutAuth?: boolean;
  headers?: HeadersInit | undefined;
  next?: NextFetchRequestConfig;
}): Promise<T> => baseRequest(url, { method: Methods.POST, body, headers, next }, withoutAuth);

export const postFormDataRequest = <T>({
  url,
  body,
  headers,
  next,
}: {
  url: string;
  body?: FormData;
  headers?: HeadersInit | undefined;
  next?: NextFetchRequestConfig;
}): Promise<T> =>
  baseFormDataRequest(url, {
    method: Methods.POST,
    body,
    headers: { ...headers },
    next,
  });

export const putRequest = <T>(
  url: string,
  body?: BodyInit | Record<string, unknown>,
  next?: NextFetchRequestConfig,
): Promise<T> => baseRequest(url, { method: Methods.PUT, body, next });

export const patchRequest = <T>(
  url: string,
  body?: BodyInit | Record<string, unknown>,
  next?: NextFetchRequestConfig,
): Promise<T> => baseRequest(url, { method: Methods.PATCH, body, next });

export const deleteRequest = <T>(url: string, next?: NextFetchRequestConfig): Promise<T> =>
  baseRequest(url, { method: Methods.DELETE, next });

export const getRequest = <T>({
  url,
  query,
  headers,
  withoutAuth,
  next,
}: {
  url: string;
  query?: Record<string, unknown>;
  headers?: Record<string, string>;
  withoutAuth?: boolean;
  next?: NextFetchRequestConfig;
}): Promise<T> => {
  const reqUrl =
    isEmpty(query) || !query
      ? url
      : `${url}${url.includes('?') ? '&' : '?'}${objectToQueryString(query)}`;
  return baseRequest(reqUrl, { headers, next }, withoutAuth);
};
